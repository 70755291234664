<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerEPurchaseTermsV1 from '/~/components/drawer/views/e-purchases/drawer-e-purchase-terms.vue'

export default {
  name: 'epurchase-terms',
  components: {
    BaseAsidePage,
    BaseButton,
    BaseIcon,
  },
  extends: DrawerEPurchaseTermsV1,
}
</script>

<template>
  <base-aside-page class="[&_a]:break-words">
    <template #iconHeader>
      <div />
    </template>
    <div
      class="relative mb-[30px] flex h-72 flex-shrink-0 items-center justify-center bg-center bg-no-repeat pt-2.5"
      :style="{
        backgroundImage: `url('/icons/v3/empty-blue.svg')`,
      }"
    >
      <div
        class="flex h-[120px] w-[120px] items-center justify-center rounded-full bg-primary text-white"
      >
        <base-icon svg="v2/box-icons/bx-file" :size="75" />
      </div>
      <div class="absolute right-0 top-0">
        <base-button icon="plain/close" @click="back" />
      </div>
    </div>
    <div class="mb-10 mt-2.5 text-center text-2xl font-bold">
      {{ title }}
    </div>
    <div v-if="terms.redemption" v-html="terms.redemption" />
    <div v-if="terms.online" v-html="terms.online" />
    <div v-if="terms.inStore" v-html="terms.inStore" />
    <div v-if="terms.salesAssociate" v-html="terms.salesAssociate" />
    <div v-if="terms.terms" class="mt-6">
      <span v-html="terms.terms" />
    </div>
  </base-aside-page>
</template>
